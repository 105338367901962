<template>
  <div class="absolute right-10 top-[3.9rem] BG0 w-[500px] px-6 py-3 shadow-md z-50 rounded N800 P200">
    <h3 class="H400 N900 my-4">Apps</h3>
    <div :class="`grid grid-cols-4 w-fit justify-items-center gap-x-10 gap-y-5 px-3`">
      <div v-for="(app, index) in displayedApps" :key="index" :text="app.text">
        <a href="javascript:void(0)" @click="routeUrl(app.link)"
          class="group w-[116px] h-[110px] rounded-lg group hover:bg-[#FFF0EA] py-2 flex flex-col justify-center items-center N700 P100 text-center group-hover:text-[#DD5928]"
          @mouseenter="fill(app.key, true)" @mouseleave="fill(app.key, false)">
          <component :is="getIcon(app)" class="self-center" :filled="isFilled(app.key)" />
          <span style="white-space: pre-line;">{{ app.text }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, ref } from "vue";
import { useStore } from "@/store";
import config from '@/config/config';
import { AppType, FilledStates } from "@/types/componentTypes";
import PerformanceSvg from "../assets/menu-icons/PerformanceSvg.vue";
import PerformanceSvgFilled from "../assets/menu-icons/PerformanceSvgFilled.vue";
import EmployeeManagerSvg from "../assets/menu-icons/EmployeeManagerSvg.vue";
import EmployeeManagerSvgFilled from "../assets/menu-icons/EmployeeManagerSvgFilled.vue";
import ContractSvg from "../assets/menu-icons/ContractSvg.vue";
import ContractsSvgFilled from "../assets/menu-icons/ContractsSvgFilled.vue";
import ClockITSvg from "../assets/menu-icons/ClockITSvg.vue";
import ClockITSvgFilled from "../assets/menu-icons/ClockITSvgFilled.vue";
import PayrollSvg from "../assets/menu-icons/PayrollSvg.vue";
import PayrollSvgFilled from "../assets/menu-icons/PayrollSvgFilled.vue";
import LeaveSvg from "../assets/menu-icons/LeaveSvg.vue";
import LeaveSvgFilled from "../assets/menu-icons/LeaveSvgFilled.vue";
import InvoiceSvg from "../assets/menu-icons/InvoiceSvg.vue";
import InvoiceSvgFilled from "../assets/menu-icons/InvoiceSvgFilled.vue";
import ProcurementSvg from "../assets/menu-icons/ProcurementSvg.vue";
import ProcurementSvgFilled from "../assets/menu-icons/ProcurementSvgFilled.vue";
import IncidenceManagerSvg from "../assets/menu-icons/IncidenceManagerSvg.vue";
import IncidenceManagerSvgFilled from "../assets/menu-icons/IncidenceManagerSvgFilled.vue";
import MeetingRoomBookingSvg from "../assets/menu-icons/MeetingRoomBookingSvg.vue";
import MeetingRoomBookingSvgFilled from "../assets/menu-icons/MeetingRoomBookingSvgFilled.vue";
import { getToken } from "@/services/auth";

const user = useStore()?.user;
const filledStates: Ref<FilledStates> = ref({});
const permitedApps: string[] | undefined = user?.apps;

const fill = (appKey: string, status: boolean): void => {
  filledStates.value[appKey] = status;
};



function getIcon(app: AppType) {
  return filledStates.value[app.key] ? app.filledIcon : app.icon;
}

const isFilled = (appKey: string): boolean => {
  return filledStates.value[appKey];
};

const apps: AppType[] = [
  { text: "Performance Manager", link: config.performanceLink, icon: PerformanceSvg, filledIcon: PerformanceSvgFilled, key: "performance management" },
  { text: "Employee Manager", link: config.employeeManagerLink, icon: EmployeeManagerSvg, filledIcon: EmployeeManagerSvgFilled, key: "employee management" },
  { text: "Contract Manager", link: config.contractManagerLink, icon: ContractSvg, filledIcon: ContractsSvgFilled, key: "contract management" },
  { text: "Clock-IT", link: config.clockItLink, icon: ClockITSvg, filledIcon: ClockITSvgFilled, key: "time tracker" },
  { text: "Payroll", link: config.payrollLink, icon: PayrollSvg, filledIcon: PayrollSvgFilled, key: "payroll" },
  { text: "Invoice Manager", link: config.invoiceManagerLink, icon: InvoiceSvg, filledIcon: InvoiceSvgFilled, key: "invoice manager" },
  { text: "Procurement Manager", link: config.procurementManagerLink, icon: ProcurementSvg, filledIcon: ProcurementSvgFilled, key: "procurement manager" },
  { text: "Leave Manager", link: config.leaveManagerLink, icon: LeaveSvg, filledIcon: LeaveSvgFilled, key: "leave management" },
  { text: "Loan Manager", link: config.loansManagerLink, icon: PayrollSvg, filledIcon: PayrollSvgFilled, key: "loans" },
  { text: "Incidence Manager", link: config.incidenceManagerLink, icon: IncidenceManagerSvg, filledIcon: IncidenceManagerSvgFilled, key: "incidence manager" },
  { text: "Meeting Room Booking", link: config.meetingRoomBookingLink, icon: MeetingRoomBookingSvg, filledIcon: MeetingRoomBookingSvgFilled, key: "meeting booking" },
];

const routeUrl = (link: string = "") => {
  const token: string | null = getToken();
  if (token) {
    window.location.href = `${link}`; 

  }
}
const displayedApps = computed(() => {
  return apps.filter(app => permitedApps?.includes(app.key)).sort((a, b) => a.text.localeCompare(b.text));
});
</script>