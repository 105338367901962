
const ResourceComponent = (path:string) => () =>
  import(/* webpackChunkName: "resource" */ `../views/${path}.vue`);

export default [

  {
    path: "/client-manager",
    name: "Client manager",
    meta: { auth: true },
    component: ResourceComponent("ClientManager")
  },
  {
    path: "/view-client/:id",
    name: "View client",
    meta: { auth: true },
    component: ResourceComponent("ViewClient")
  },
  {
    path: "/project-manager",
    name: "Project manager",
    meta: { auth: true },
    component: ResourceComponent("ProjectManager")
  },

  {
    path: "/specialisation",
    name: "Specialisation",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "resource" */ "../views/SpecialisationPage.vue"
      )
  },
  {
    path: "/settings/specialisation",
    name: "Settings",
    meta: { auth: true },
    component: () =>
    import(
      /* webpackChunkName: "resource" */ "../views/SettingsView.vue"
    ),
    children:[
      {
        path: "/settings/specialisation",
        name: "Specialisation",
        meta: { auth: true },
        component: ResourceComponent("SpecialisationPage")
      },
      {
        path: "/settings/project-config",
        name: "Project Configuration",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/ProjectConfiguration.vue"
          )
      },
      
      {
        path: "/settings/assessment-config",
        name: "Assessment Configuration",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/AssessmentConfiguration.vue"
          )
      },
      {
        path: "/settings/access-level",
        name: "Access Level",
        meta: { auth: true },
        component: ResourceComponent("AccessLevelView")
      },
      {
        path: "/settings/offices",
        name: "Offices",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/OfficesView.vue"
          )
      },
      {
        path: "/settings/organisations",
        name: "Organisations",
        meta: { auth: true },
        component: ResourceComponent("OrganizationsView")
      },
      {
        path: "/settings/skill-categorisation",
        name: "Skill Categorisation",
        meta: { auth: true },
        component: () =>
          import(/* webpackChunkName: "config" */ "../views/SkillCategorisation.vue")
      },
    ]
  },

  {
    path: "/create-organisations",
    name: "Create Organisations",
    meta: { auth: true },
    component: () =>
    import(
      /* webpackChunkName: "config" */ "../../resource-manager/views/createOrganization.vue"
    )      
  },
 
  {
    path: "/project-information/:id/team",
    name: "Project information",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "project-details" */"../../resource-manager/views/ViewProjectInformation.vue"),
    children: [
      {
        path: "",
        name: "Team",
        props:true,
        component: () => import(/* webpackChunkName: "project-details" */ "../../resource-manager/views/ProjectTeam.vue")
      },

      {
        path: "Documents",
        name: "Documents",
        component: () =>
          import("../../resource-manager/views/ProjectDocument.vue")
      },

      {
        path: "project-notes",
        name: "Project notes",
        component: () =>
          import(
            /* webpackChunkName: "project-notes" */ "../../resource-manager/views/ProjectNotes.vue"
          )
      },
      {
        path: "feedback-config",
        name: "Feedback Configuration",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "config" */ "../views/AssessmentConfiguration.vue"
          )
      },
    ]
  },


  {
    path: "/staff-manager",
    name: "Talent pool",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "staffManager" */ "../views/TalentPool.vue"),
    children:[
      {
        path:"",
        props:true,
        name:"Table view",
        component:() =>
          import(/* webpackChunkName: "staffManager" */ "../views/StaffManagerTableView.vue"),
      },
      {
        path:"timeline",
        props:true,

        name:"Timeline view",
        component:() =>
          import(/* webpackChunkName: "staffManager" */ "../views/TimeLineView.vue"),
      }
    ]
  },
  {
    path: "/skill-manager",
    name: "Skill manager",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "skillsmanager" */ "../views/SkillManager.vue")
  },
  {
    path: "/create-project",
    name: "Create project",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "createProject" */ "../views/CreateProjectStepper.vue"
      )
  },

  {
  path: "/create-access",
    name: "Create access",
    meta: { auth: true },
    component: ResourceComponent("CreateAccessLevel")
  },

  {
    path: "/create-access/:id",
    name: "Edit access",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "create-access" */ "../views/CreateAccessLevel.vue"
      )
  },

  {
    path: "/create-project/:id",
    name: "Edit project",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "createProject" */ "../views/CreateProjectStepper.vue"
      )
  },

  {
    path: "/create-client/",
    name: "Create client",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "resource" */ "../views/CreateClient.vue")
  },
  {
    path: "/create-client/:id",
    name: "Edit client",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "resource" */ "../views/CreateClient.vue")
  },

  {
    path: "/help/resource-manager/",
    name: "Help",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "resource" */ "../views/HelpView.vue")
  },
{
path:"/not-authorized",
name:"Not authorized",
component:()=>(import(/* webpackChunkName: "not-authorized" */ "../views/NotAuthorizedPage.vue" ))
},
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    meta: { auth: true },
    component: ()=>(import(/* webpackChunkName: "page-not-found" */ "../../PageNotFound.vue"))
  }
];
